<template>
  <div style="height:89vh;background:#F5F7FB;padding-top:6rem;" @click="show = false">
    <!-- 注册 -->
    <h3 class="title">欢迎来到企宣通</h3>
    <p class="action">开启您的品牌宣传之路</p>
    <div class="ct_box flex">
      <img src="../assets/register/banner.png" alt="">
      <div class="form_box">
        <p style="color: #168AD9;font-size: 2.6rem;text-align: center;margin-bottom: 1rem;font-weight: bold;">注册</p>
        <div class="login_box flex">
          <p>用户名:</p>
          <el-input v-model="form.title" placeholder="请输入用户名"></el-input>
        </div>
        <div class="login_box flex">
          <p>手机号:</p>
          <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
        </div>
        <div class="login_box flex">
          <p>密码:</p>
          <el-input type="password" v-model="form.password" placeholder="请输入密码"></el-input>
          <!-- <span class="hint" slot="reference"></span> -->
          <el-tooltip placement="top">
            <div slot="content">
              <p>长度为8~14个字符</p>
              <p>字母/数字以及标点符号至少包含2种</p>
              <p>不允许有空格、中文 </p>
            </div>
            <el-button></el-button>
          </el-tooltip>
        </div>
        <div class="flex" style=" align-items: center;">
          <div class="login_box flex" style="margin:0 1rem 0 0;width: 70%; ">
            <!-- <p>验证码:</p> -->
            <el-input class="code_input" v-model="form.code" placeholder="请输入短信验证码"></el-input>
          </div>
          <el-button @click.stop="verifyFn" style="width: 40%;" :disabled="codeState">{{ codeState ? `${codeNum}s` :
            '获取验证码'
          }}</el-button>

        </div>
        <div class="code_box" v-show="show">
          <el-card>
            <div class="page-slidecode">
              <slide-verify ref="slideblock" :l="42" :r="10" :w="310" :h="155" :imgs="bgimgs" @fulfilled="onFulfilled"
                @success="onSuccess" @fail="onFail" @refresh="onRefresh" :slider-text="text"></slide-verify>
              <div>{{ msg }}</div>

            </div>
          </el-card>
        </div>
        <!-- <button ></button> -->
        <!-- <div> <el-button style="margin: 1.2rem auto;" :style="form.verify ? 'width: 60%;' : '100%'"
            :type="form.verify ? 'success' : 'info'">{{ form.verify ? '已完成' : '请进行非人机验证' }}</el-button>
        </div> -->
        <el-button style="width: 100%;margin: 1.2rem 0;" class="login_but" type="primary"
          @click="registerFn">注册</el-button>
        <div class="flex">
          <el-checkbox-group v-model="form.clause">
            <el-checkbox name="type"></el-checkbox>
          </el-checkbox-group>
          <p style="color: #999999; font-size: 1.2rem;margin-left: 0.8rem;">我已阅读并同意<span
              style="font-size: 1.2rem;color: #168AD9; cursor: pointer;" @click="popShow">《企宣通服务条款》</span></p>
        </div>
        <p style="color: #999999;font-size: 1.2rem;margin: 1rem 0;text-align: center;cursor: pointer;">已有帐号?<span
            style="color:#168AD9;font-size: 1.2rem;" @click="goloinFn()">立即登录</span></p>
      </div>
      <el-dialog width="80%" top="5vh" title="" :append-to-body="true" :visible.sync="pop_up">
        <h4 class="popCont_title">{{ popCont.title }}</h4>
        <p v-html="popCont.content"></p>

      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  created() { },
  mounted() {
    this.curlGet('/api/article/list', {
      cate_id: 5
    }).then(res => {
      console.log(res);
      if (res.data.code) {
        this.popCont = res.data.data[0]
      }
    })
  },
  data() {
    return {
      form: {
        mobile: '',//手机号
        title: '', //账号
        password: '', //密码
        clause: false, //服务条款
        code: '', //短信验证码
        verify: false, //是否已做过人机验证
      },
      loading: false,
      show: false,
      // butShow: true,
      msg: "", //提示文本
      bgimgs: [
        require('@/assets/product/bg2.png'),
        require('@/assets/register/img2.jpg'),
        require('@/assets/register/img3.jpg'),
      ],
      text: "向右滑动~",
      timer: null, // 发送验证码-定时器
      codeNum: 60,
      codeState: false,
      // 服务条款弹窗
      pop_up: false,
      popCont: {}
    }
  },
  methods: {
    // 发送验证码
    registerFn() {
      if (this.form.title == '') {
        return this.$message({
          message: '请输入用户名',
          type: 'warning'
        });
      } else if (this.form.title.length < 3 || this.form.title.length > 30) {
        return this.$message({
          message: '用户名的字符在3-30之间',
          type: 'warning'
        });
      } else if (this.form.mobile == '') {
        return this.$message({
          message: '请输入手机号',
          type: 'warning'
        });
      } else if (this.form.password == '') {
        return this.$message({
          message: '请输入密码',
          type: 'warning'
        });
      } else if (this.form.code == '') {
        return this.$message({
          message: '请输入短信验证码',
          type: 'warning'
        });
      } else if (this.form.clause == false) {
        return this.$message({
          message: '请阅读并同意服务条款',
          type: 'warning'
        });
      }
      this.loading = true
      /*----------------接口-------------------*/
      this.curlPost('/api/user/register',
        {
          account: this.form.title,
          mobile: this.form.mobile,
          password: this.form.password,
          confirm_password: this.form.password,
          code: this.form.code
        }).then(res => {
          this.loading = false

          if (res.data.code) {
            this.$util.setLocalStorage('user_info', res.data.data.userinfo) // 写入local storage

            this.$message({
              message: '注册成功',
              type: 'success'
            });
            this.onRefresh()
            setTimeout(() => {
              this.$router.push({
                path: '/'
              })
            }, 1200);
          } else {
            this.onRefresh()
            this.$message({
              message: res.data.msg,
              type: 'warning'
            });
          }

        })

    },
    // 点击进行非人机验证
    verifyFn() {
      const phoneReg = /^1\d{10}$/
      if (this.form.mobile == '' || !phoneReg.test(this.form.mobile)) {
        return this.$message({ message: '请输入手机号', type: 'warning' });
      }

      // if (this.form.verify == false) {
      this.show = true
      // }
    },
    onSuccess() {
      this.msg = "验证成功~";
      this.form.verify = true
      this.codeState = true
      this.show = false
      // 定时器
      this.timer = setInterval(() => {
        this.codeNum--
        if (this.codeNum == 0) {
          clearInterval(this.timer)
          this.codeState = false
          this.onFulfilled()

          return this.codeNum = 60

        }
      }, 1000);

      // 发送验证码
      this.curlGet('/api/index/sendcode', { mobile: this.form.mobile }).then(res => {
        this.$refs.slideblock.reset()
        if (res.data.code) {
          this.$message({ message: '发送成功', type: 'success' });
          this.onFulfilled()
        } else {
          this.$message({ message: '发送失败', type: 'error' });
          this.onFulfilled()

        }
      })

    },
    onFulfilled() {
      console.log('刷新成功啦！');
    },
    onFail() {
      this.msg = "登录失败！";
      this.$refs.slideblock.reset()

    },
    onRefresh() {
      this.msg = "重新生成验证码";
      this.$refs.slideblock.reset()

    },
    goloinFn() {
      this.$router.push({
        path: '/',
        query: {
          login: 0
        }

      })
    },
    popShow() {
      this.pop_up = true
    }
  },
}
</script>

<style lang=scss scoped>
// ::v-deep .el-popover__reference-wrapper{
//   display: none;
// }
.title {
  color: #000000;
  font-size: 3rem;
  text-align: center;
}

.action {
  color: #9E9E9F;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.ct_box {
  width: 55%;
  background: #fff;
  border-radius: 1rem;
  padding: 4rem 4rem;
  margin: 0 auto;
  box-sizing: border-box;
  justify-content: space-between;

  img {
    width: 65%;
    margin-right: 1.5rem;
    border-radius: 1rem;
  }

  .form_box {
    width: 30%;

    .login_box {
      border: 0.1rem solid #DDDDDD;
      border-radius: 0.6rem;
      align-items: center;
      padding: 0 1rem;
      margin: 0 0 1.5rem 0;
      position: relative;
      justify-content: space-between;
      ::v-deep .el-button {
        position: absolute;
        right: 11px;
        top: 11px;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        background: url('../assets/register/wenhao.png')no-repeat;
        background-size: 100%;
        border: 0;
        padding: 0;
      }

      p {
        color: #999999;
        // width: 6rem;
        font-size: 1.2rem;
      }
      .code_input{
        width: 100% !important;
      }
      ::v-deep .el-input{
        width: 70%;
      }
      ::v-deep .el-input__inner {
        border: 0;
        padding: 0;
      }
    }

    .code_box {
      position: relative;

      .el-card {
        position: absolute;
        top: -258px;
        left: 10px;
        transition: 0.2s all;
      }
    }

  }

}

// ::v-deep .el-dialog__header {
//   display: none;
// }
::v-deep .el-dialog__body {
  padding-top: 0;
}

.el-dialog__headerbtn .el-dialog__close {
  font-size: 2rem;
}

.popCont_title {
  text-align: center;
  font-size: 1.6rem;
}

.popCont_content {
  p {
    font-size: 1.4rem;
  }
}
</style>